@import 'base/base';
@import 'utilities/vars';
@import 'utilities/mixins';
@import 'layout/main';
@import 'modules/error404';

body { padding: 50px 0 0 !important; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.confirm {display: none;}

.page-wrapper {
	width: 100%;
	height: 100%;
	background-color: $light;
	#top-side {
		display: block;
		padding: 122px 0 48px;
		text-align: center;
		background-color: $white;
		#logo { margin-bottom: 18px; }
		#err_title {
			@extend .typo-ui-h3, .dark;
			font-family: $roboto;
			margin-bottom: 60px;
		}
		#searchline {
			position: relative;
			display: block;
			width: 100%;
			max-width: 512px;
			height: 36px;
			margin: 0 auto;
			text-align: right;
			padding-right: 89px;

			#searchfield {
				@extend .input;
				position: relative;
				display: inline-block;
				border: none;
				font-size: 1.4rem;
				line-height: 1.4rem;
				width: 100%;
				height: 36px;
				padding: 0;
				border-radius: 0;
				border-bottom: 2px solid $grey-second;
				&:hover:not(:disabled):not(:focus):not(.error):not(.success){ border-color: $grey-second; }
			}
			#clearsearch {
				display: block;
				position: absolute;
				top: 0;
				right: 83px;
				cursor: pointer;
				width: 16px;
				height: 36px;
				margin-right: 12px;
				padding-top: 10px;
			}
			#searchbtn {
				@extend .button, .button-blue-bordered, .button-middle;
				position: absolute;
				top: 0;
				right: 0;
				float: right;
				cursor: pointer;
			}
		}
	}
	#bottom-side {
		display: block;
		width: 100%;
		text-align: center;
		padding-top: 42px;
		padding-bottom: 72px;
		background-color: $light;
		#to_main {
			@extend .typo-ui-h3, .dark;
			font-weight: normal;
			letter-spacing: 0;
			margin-bottom: 18px;
			a {
				@extend .link;
				text-decoration: none;
				color: $blue;
				&:hover { color:darken($blue, 10%); }
			}
		}
		#err_or {
			position: relative;
			margin-bottom: 18px;
			height: 18px;
			span {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 300px;
				height: 2px;
				background-color: $grey-plus;
				margin: auto;
				z-index: 0;
			}
			div {
				@extend .typo-ui-txt, .grey;
				background-color: $light;
				display: inline-block;
				position: absolute;
				margin: auto;
				width: 42px;
				left: 0;
				right: 0;
			}
		}
		#sw_wrap {
			display: block;
			text-align: center;
			width: 100%;
			margin-bottom:72px;
			#start_writing { @extend .button, .button-yellow, .button-middle; }
		}
		#report_bug {
			@extend .button-link, .brackets, .blue;
		}
	}
}
@media all and (max-width:536px) {
	.page-wrapper {
		#top-side {
			#searchline {
				padding-left: 10px;
				padding-right: 99px;
				#clearsearch{ right: 90px; }
				#searchbtn { right: 10px; }
			}
		}
	}
}
