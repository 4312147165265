@import 'dialog';
body { padding: 50px 0 0px !important; }

footer {
    height: 115px;
    background: $dark;
}

.foot-wrap {
	width: 1170px;
    margin: auto;
    padding-top: 39px;

    .social_links {
        float: right;

        a {
			display: inline-block;
            &:not(:first-child) {
                margin-left: 12px;
            }
			&:hover {
				.iw use.i-norm { opacity: 0; }
				.iw use.i-act { opacity: 1; }
			}
			svg {pointer-events: none;}
			use.i-act {fill: $blue;}
        }
    }
	.description {
		@extend .typo-ui-txt;
		color: #fff;
		width: 196px;
		margin-left: 18px;
		display: inline-block;
		vertical-align: top;
	}
}

.footer-logo {
	position: relative;
	top: 4px;
	text-decoration: none;
	.logo {
		background: url(/img/uPages_Logo.svg);
		margin-right: 10px;
		height: 32px;
		width: 32px;
		vertical-align: middle;
		position: relative;
		top: -3px;
		display: inline-block;
	}
	.site {
		@extend .typo-ui-txt-medium;
		color: $white;
	}
	img {height: 34px;}
}

.project-links {
    float: right;
    height: 36px;
	white-space: nowrap;
	margin-right: 24px;
    a {
		@extend .typo-ui-txt;
		color: #fff;
        text-decoration: none;
        line-height: 32px;
        transition: color 0.2s;
		margin-right: 18px;

        &:hover {
            color: $blue;
        }
    }
}

.footer-article {
	height: 60px;
	width: 100%;
	padding: 0 36px;
	background: $white;
	border-top: 2px solid $extra-green;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	opacity: 1;
	transition: opacity 0.2s;
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

	.foot-wrap {
		width: 1100px;
		padding-top: 0;
		&>* { display: inline-block; }
		svg { cursor: pointer; }
		.publication-info-panel {
			float: left;
			margin-top: 24px;
			margin-left: 22px;
			h5 {
				display: inline-block;
				padding-right: 8px;
				padding-left: 6px;
				border-right: 2px solid $grey-text;
				text-transform: none;
				line-height: 12px;
				&:last-child {
					border: 0;
				}
				&:first-child {
					padding-left: 0;
				}
			}
		}
		.author-info {
			float: left;
			margin-top: 14px;
			.author-name {
				position: relative;
				top: 1px;
				text-decoration: none;
				margin-left: 12px;
				margin-right: 24px;
				&:hover { color: #000; }
			}
			.button {
				height: 30px;
				padding: 6px 15px;
				&.followed {
					transition: padding 0s, background-color 0.2s, border-color 0.2s;
					@extend .button-green;
					span { display: none; }
					&:after {
						content: 'Вы подписаны';
					}
					&:hover {
						padding: 6px 25.5px;
						color: $white;
						background-color: $grey-second !important;
						border-color: $grey-second;
						&:after {
							content: 'Отписаться';
						}
					}
				}
			}
		}
		.author-photo {
			height: 32px;
			width: 32px;
			float: left;
			border-radius: 50%;
			overflow: hidden;
			display: flex;
			justify-content: center;

			img {
				object-fit: cover;
				max-height: 32px;
				min-width: 32px;
			}
		}
		.like-btn, .to-comments {
			margin-right: 24px;
			float: right;
			&>* { float: left; }
			svg { margin-top: 15px; }
			h5 {
				margin-top: 24px;
				margin-left: 12px;
				transition: color 0.3s;
				&.active { color: $dark; }
			}
		}
		.favorite {
			height: 32px;
			float: right;
			&>* { display: inline-block; }
			border-left: 2px solid $grey-second;
			padding-left: 30px;
			padding-right: 30px;
			margin-top: 14px;
			p {
				float: left;
				margin-top: 8px;
				margin-right: 18px;
			}
			.share-btn {
				display: none;
				&:hover {use {fill: $grey;}}
			}
		}
		.social_links {
			height: 32px;
			padding-left: 30px;
			border-left: 2px solid $grey-second;
			margin-top: 14px;
			p {
				float: left;
				margin-top: 8px;
			}
		}
	}
}

.uSocial-Share {
	display: inline-block;
	margin-top: -3px;
	margin-left: 3px;
}

@media all and (max-width: 1399px) {
	.footer-article {
		.foot-wrap {
			.publication-info-panel { display: none; }
		}
	}
}

@media all and (max-width: 1199px) {
	footer {
		height: 70px;
		&:not(.footer-article) .foot-wrap {
			width: 100%;
			padding: 20px 30px 0;
		}
    }
	.foot-wrap {
		.description {display: none;}
	}
	.footer-logo {
		top: 5px;
		img {height: 27px;}
	}
	.footer-article {
		padding: 0 30px;
		.foot-wrap {
			width: 970px;
			.favorite {
				padding-right: 0;
				//svg:first-of-type { margin-right: 24px; }
				svg:last-of-type { display: inline-block; }
			}
			.social_links { display: none; }
			.like-btn {
				.i-act:hover { opacity: 0; }
				&.active {
					.i-act:hover { opacity: 1; }
				}
			}
		}
	}
}

@media all and (max-width: 1045px) {
	.footer-article {
		.foot-wrap {
			.favorite p { display: none; }
		}
	}
}

@media all and (max-width: 1023px) {
	.footer-article { .foot-wrap { width: 708px; } }
}

@media all and (max-width: 1060px) {
	body { padding: 50px 0 248px !important; }
    footer {
        height: auto;
        text-align: center;

		&:not(.footer-article) .foot-wrap {
			padding: 24px 30px 0;
		}

        .foot-wrap {
			position: relative;
			.social_links {
				float: none;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 24px;
			}

            .project-links {
                float: none;
                height: auto;
				width: 100%;
				padding-top: 18px;
                padding-bottom: 80px;

                a {
                    display: block;
                    margin-right: 0;
                    height: 30px;
                    line-height: 30px;

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
	.footer-article {
		.foot-wrap {
			width: 100%;
			.author-info {
				.author-name { top: 6px; }
				.button { display: none; }
			}
		}
	}
}

@media all and (max-width: 567px) {
	.footer-article {
		padding: 0 10px;
		.foot-wrap {
			.author-info {
				.author-name { display: none; }
			}
			.like-btn { margin-right: 18px; }
			.to-comments { margin-right: 12px; }
			.favorite { padding-left: 12px; }
		}
	}
}
